.contactForm { 
    /* border: 1px solid red; */
    height: fit-content;
    width: 700px;
    margin: 60px auto 60px ;
    text-align: left;
    box-sizing: border-box;
    
}


.form-group { 
    /* border: 1px solid pink; */
    min-height: 3rem;
    color: var(--color__dark-blue);
    margin: 20px auto;
    max-height: 3rem;
    /* display:flex;
    flex-direction: row; */
    box-sizing: border-box;
    transition: all 1s ease;
}

.flexInDesktop { 
    display: flex;
}

label { 
    display: inline-block;
    width: 150px;
    font-size: 20px;
    font-weight: 300;
    line-height: 3rem;
    margin-bottom: 3px;
    max-height: 3rem;
    
}
input, textarea { 
    width: 550px;
    height: 3rem;
    border-radius: 5px;
    border: none;
    border: 2px solid var(--color__mid-blue);
    border-radius: 10px;
    padding: 2px 15px;
    box-sizing: border-box;
    font-size: 18px;
    background-color: var(--color__Light-gray);
    line-height: 2.5rem;
    max-height: 3rem;
    max-width: 550px;
    min-height: 3rem;
    min-width: 550px;
    color: var(--color__dark-gray)
}
input:focus, textarea:focus { 
    outline-color: var(--color__orange);
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
    color: var(--color__dark-gray-fix)
}
.form-group button { 
    width: 160px;
    height: 40px;
    margin: 5px;
    background-color: var(--color__dark-blue);
    color: var(--color__Light-gray);
    border: none;
    border-radius: 10px;
    font-size: var(--font-size__18);
    font-weight: var(--font-weight__bold);
    margin: 5px;
}

.error-message { 
    font-size: 12px;
    color: red;
    width: 100%;
}
.form-group-btns { 
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.FormSend { 
    text-align: center;
    margin: auto;
    width: 60%;
    height: fit-content;
    padding: 10px;
    max-width: 500px;
    border: 2px solid var(--color__mid-blue);
    border-radius: 10px;
}

@media screen and (max-width: 740px) { 
    .contactForm { 
        width: 100%;
        text-align: center;
    }
    .form-group { 
        display: flex;
        flex-direction: column;
        min-width: 100%;
        max-width: 3rem;
        max-height: unset;
        min-height: unset;
    }
    label { 
        display: block;
        width: unset;
        line-height: 1.5em;
    }
    input, textarea { 
        margin: 0 auto;
    }
    input { 
        width: 80%;
        min-width: 80%;
        max-width: 3rem;
        max-height: unset;
        min-height: unset;
    }
    textarea { 
        width: 80%;
        max-width: 80%;
        min-width: 80%;
        min-height: 3rem;
        max-height: 100px;
    }
    .form-group button { 
        margin: 8px auto;
    }
    .form-group-btns { 
        flex-direction: column;
        margin-top: 40px;
    }

    .flexInDesktop { 
        display: unset;
    }
}