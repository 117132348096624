.podcasterBox { 
    width: 300px;
    display: flex;
    flex-direction: column;
    background-color: var(--color__yellow);
    border-radius: 15px;
    margin: 5px;
}
.podcasterImage { 
    width: 300px;
    height: 250px;
    border-radius: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.podcasterDesc {
    padding-left: 10px;
}
.podcasterDesc h2 { 
    margin: 5px 0;
    font-weight: 800;
}
.podcasterDesc h4 { 
    margin-bottom: 5px;
}
.podcasterDesc p { 
    margin-bottom: 10px;
    font-weight: 700;
}