@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@400;700&display=swap');

.SaveEarth { 
    width: 100vw;
    max-width: 1300px;
    min-width: 580px;
    margin: auto;
    min-height: 500px;
    background-color: #F5F5F5;
    box-sizing: border-box;
}

.saveEarth__header { 
    height: 15rem;
}
.saveEarth__header_background { 
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.saveEarth__header_icons ul { 
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content:space-around ;
    transform: translateY(10rem);
    height: 6rem;
}
.saveEarth__header_icons ul li { 
    list-style: none;
}
.saveEarth__header_button_hover { 
    width: 120px;
    height: 2rem;
    margin: 2rem 0;
    background-color: #F5F5F5;
    border: none;
    border-radius: 10px;
}
.saveEarth__header_button_hover:hover { 
    background-color: #FDFBEE !important;
}


.saveEarth__intro { 
    width: 100%;
    max-width: 1300;
    margin: 0 auto;
    padding: 5rem 3rem 2rem;
    box-sizing: border-box;

}
.saveEarth__intro h1 { 
    font-family: 'Bodoni Moda', serif;
    font-weight: 700;
    letter-spacing: 12px;
    font-size: 2.8rem;
}
.saveEarth__intro p { 
    color: rgb(140, 140, 140);
}
.saveEarth__intro_motivation_phrase { 
    padding: 5px 10px;
    margin: 35px auto 10px;
    border-left: 4px solid rgb(170, 170, 170);
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
}
.saveEarth__intro_motivation_phrase p { 
    font-size: 1.5rem;
    color: rgb(140, 140, 140);
    font-family: 'Bodoni Moda', serif;
    width: 98%;
    margin: auto;
}
.saveEarth__intro_coloredBox { 
    width: 100%;
    max-width: 1200px;
    margin: auto;
    height: 35px;
    background-color: #F9F3DB;
}

.saveEarth__visualDashboard { 
    width: 100%;
    max-width: 1200px;
    margin: 10px auto 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.saveEarth__visualDashboard_calendar, .saveEarth__visualDashboard_weather_progressBar { 
    width: 50%;
    padding: 10px 0;
    box-sizing: border-box;
}
.saveEarth__visualDashboard_calendar_box { 
    width: 500px;
    height: fit-content;
    background-color: #F9F3DB;
    margin: auto auto auto 0;
    padding-bottom: 20px;
}
.saveEarth__visualDashboard_calendar_title { 
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.saveEarth__visualDashboard_calendar_box h3 { 
    margin: 40px 30px 30px;
    font-weight: 700;
    font-size: 1.6rem;
    color:rgb(50, 50, 50)
}
.saveEarth__visualDashboard_calendar_days { 
    width: 410px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px auto 10px;
}
.saveEarth__visualDashboard_week_days ul { 
    margin: 1px auto 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content:space-around ;
    width: 402px;
}
.saveEarth__visualDashboard_week_days ul li { 
    width: 56px;
    list-style: none;
    text-align: center;
}
.saveEarth__visualDashboard_calendar_day_box { 
    width: 56px;
    height: 70px;
    margin: 1px;
    border: 1px solid rgb(175, 175, 175);
    border-radius: 3px;
    text-align: center;
    padding: 2px;
    box-sizing: border-box;
}
.saveEarth__visualDashboard_events { 
    width: 500px;
    height: fit-content;
    min-height: 350px;
    background-color: #F9F3DB;
    margin: auto auto auto 0;
    padding-bottom: 20px;
}
.saveEarth__visualDashboard_events ul { 
    margin: 30px auto;
    padding: 0;
    width: 350px;
}
.saveEarth__visualDashboard_events ul li { 
    list-style: none;
    height: 2rem;
}
.saveEarth__visualDashboard_events button { 
    width: 100px;
    height: 2.1rem;
    background-color: transparent;
    border: 1px solid rgb(175, 175, 175);
    border-radius: 3px;
    text-align: center;
    padding: 2px;
    margin: 20px 0 0 0;
    box-sizing: border-box;
}

.saveEarth__visualDashboard_calendar_empty_box { 
    width: 56px;
    height: 70px;
    margin: 1px;
}
.saveEarth__visualDashboard_calendar_day_box:hover { 
    background-color: rgba(255, 255, 255, 0.808);
}


.saveEarth__visualDashboard_weather { 
    width: 90%;
    height: 19rem;
    border-radius: 3px;
    background-color: #DDF1F8;
}
.saveEarth__visualDashboard_weather__top { 
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 40%;
    box-sizing: border-box;
}
.saveEarth__visualDashboard_weather__top div { 
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.saveEarth__visualDashboard_weather__top div p {
    width: 70%;
    text-align: left;
    line-height: 1.7rem;
}
.saveEarth__visualDashboard_weather__button ul { 
    margin: 0;
    margin-top: 20px;
    padding: 5px 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-around;
}
.saveEarth__visualDashboard_weather__button ul li { 
    list-style: none;
    text-align: center;
}
.saveEarth__visualDashboard_weather__button ul li img { 
    width: 60px;
    margin: 5px 0 10px;
}

.saveEarth__visualDashboard_progressBar { 
    height: 13rem;
    width: 90%;
    margin-top: 1.5rem;
}
.saveEarth__progressBar { 
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    margin: 10px auto;
}
.saveEarth__progressBar p { 
    color: #9DB4A4;
    font-weight: 600;
    line-height: 2.3rem
}
.saveEarth__progressBar__outerBox { 
    border: 3px solid #9DB4A4;
    border-radius: 5px;
    width: 15rem;
    height: 2rem;
    display: inline-block;
}
.saveEarth__progressBar__innerBox { 
    margin: 0.2rem;
    height: 1.6rem;
    background-color: #9DB4A4;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}


.saveEarth__todo h1, .saveEarth__notes h1 { 
    font-family: 'Bodoni Moda', serif;
}

.saveEarth__todo__empty_box { 
    width: 100%;
    height: 2rem;
    background-color: #DDEEEA;
}
.saveEarth__todo__empty_box_blue { 
    width: 100%;
    height: 2rem;
    background-color: #DDF2F8;
}
.saveEarth__todo__empty_box_yellow { 
    width: 100%;
    height: 2rem;
    background-color: #F9F3DB;
}

.saveEarth__todo ul { 
    margin: 0 0 100px 5px;
    padding: 0;
}
.saveEarth__todo ul li { 
    list-style: none;
    display: flex;
    flex-direction: row;
    text-align: left;
    justify-content: left;
    height: 2rem;
}
.saveEarth__todo ul li input { 
    min-width: 1rem;
    max-width: 20px;
    width: 20px;
    height: 20px;
    max-height: 20px;
    min-height: 20px;
    margin-right: 10px;
    accent-color: #9DB4A4;
}
.saveEarth__todo ul li p { 
    line-height: 25px;
}
.saveEarth__todo { 
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}
.saveEarth__todo div { 
    width: 100%;
    padding-left: 30px;
    box-sizing: border-box;
}

.saveEarth__notes { 
    width: 90%;
    margin: auto;
}
.saveEarth__notes button { 
    width: 100px;
    height: 2.1rem;
    background-color: transparent;
    border: 1px solid rgb(175, 175, 175);
    border-radius: 3px;
    text-align: center;
    padding: 2px;
    margin: 20px 0 0 0;
    box-sizing: border-box;
}









@media screen and (max-width: 1000px) { 
    .SaveEarth { 
        width: 95vw;
        max-width: 95vw;
        min-width: 90vw;
        box-sizing: border-box;
        margin: 0 auto;
        padding: 10px 0;
        text-align: center;
        justify-content: center;
    }
    .saveEarth__header_background, .saveEarth__header, .saveEarth__intro { 
        width: 95vw;
        max-width: 95vw;
        min-width: 90vw;
        box-sizing: border-box;
        margin: 0 auto;
        padding: 10px 0;
        text-align: left;
        justify-content: center;
    }
    .saveEarth__intro h1 {
        margin-top: 40px;
    }
    .saveEarth__intro p { 
        font-size: 0.9rem;
    }
    .saveEarth__visualDashboard { 
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        max-width: 95vw;
        width: 95vw;
        min-width: 95vw;
        margin: 0;
        padding: 0;
    }
    .saveEarth__visualDashboard_calendar, .saveEarth__visualDashboard_calendar_box, .saveEarth__visualDashboard_calendar_title, .saveEarth__visualDashboard_calendar_days { 
        width: 90vw;
        max-width: 90vw;
        margin: 10px auto;
        padding: 0;
        padding-bottom: 10px;
        box-sizing: border-box;
        justify-content: center;
    }
    .saveEarth__visualDashboard_week_days ul { 
        width: 85vw;
        margin: auto;
        margin-bottom: 3px;
    }
    .saveEarth__visualDashboard_calendar_days { 
        width: 85vw;
        margin: auto;
        justify-content: left;
        text-align: center;
    }
    .saveEarth__visualDashboard_calendar_day_box { 
        width: calc(90vw/7.8);
        height: 4rem;
    }

    .saveEarth__intro_coloredBox { 
        width: 95vw;
        max-width: 95vw;
        min-width: 90vw;
        margin: 0;
    }

    .saveEarth__visualDashboard_events { 
        width: 90vw;
        max-width: 90vw;
        text-align: left;
        padding: 0;
        box-sizing: border-box;
        justify-content: center;
    }

    .saveEarth__visualDashboard_weather_progressBar { 
        width: 90vw;
        margin: 0;
        padding: 0;
        max-width: 90vw;
    }
    .saveEarth__visualDashboard_weather { 
        width: 90vw;
        margin: 0;
        padding: 0;
        max-width: 95vw;
    }
    .saveEarth__visualDashboard_weather__button ul { 
        padding: 0;
    }
    .saveEarth__visualDashboard_weather__button ul li img { 
        width: 30px;
    }
    .saveEarth__visualDashboard_progressBar { 
        width: 95vw;
        margin: 30px auto 20px;
        max-width: 95vw;
        height: fit-content
    }

    .saveEarth__progressBar p { 
        line-height: 1.5rem;
    }
    .saveEarth__visualDashboard_calendar_empty_box { 
        width: 100%;
        max-width: 99vw;
        margin: 0;
    }

    .saveEarth__todo { 
        flex-direction: column;
        justify-content: left;
        width: fit-content;
    }
    .saveEarth__todo div  h1 {
        font-size: 1.5rem;
    }
    .saveEarth__todo div p { 
        font-size: 0.95rem;
    }
    .saveEarth__todo div {
        padding-left: 10px;
        max-width: 95vw;
    }
    .saveEarth__todo ul li input { 
        margin: 0 5px 0 0;
        transform: translateY(7px)
    }
}