.miniPlayer { 
    width: 400px;
    min-width: 300px;
    height: 150px;
    border-radius: 15px;
    margin: 5px;
    box-sizing: border-box;
    padding: 10px;
}
.miniPlayer:last-child { 
    float: right;
}
.miniPlayer h6, .miniPlayer h3 {
    margin: 0 15px;
}
.miniPlayer h6 { 
    margin-bottom: 5px;
    margin-top: 10px;
}
.miniAudio { 
    width: 250px;
    margin: 10px auto 0;
    /* border: 1px solid red; */
}
.miniAudio::-webkit-media-controls-time-remaining-display { 
    display: none;
}
.miniAudio::-webkit-media-controls-current-time-display { 
    display: none;
}

.miniAudio::-webkit-media-controls-panel { 
    background-color:rgba(241, 241, 241, 0.16);
}
.miniAudio::-webkit-media-controls-enclosure {
    background-color: transparent;
}
.miniAudio::-webkit-media-controls-play-button { 
    background-color: white;
    transform: scale(1);
    margin-right: 0;
}

.miniPlayerImage { 
    width: 100px;
    height: 100px;
    float: left;
    border-radius: 10px;
    margin: 15px 5px;
    background-color:rgb(255, 224, 161);
    box-sizing: border-box;
    text-align: center;
}

.miniPlayerImage p { 
    line-height: 100px;
    font-size: 3rem;
    color: white;
    font-weight: 700;
    margin: 0 auto;
}

@media screen and (max-width: 1000px) { 
    .miniPlayer { 
        width: 95%;
        margin: 10px auto;
    }
    .miniAudio { 
        width: 60%;
        height: 35px;
    }
    .miniPlayerImage { 
        width: 100px;
        height: 100px;
    }
}