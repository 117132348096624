@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Marck+Script&display=swap');


:root { 
  --color__dark-gray: #282828;
  --color__dark-gray-fix: #282828;
  --color__Light-gray: #f5f5f5;
  --color__dark-blue: #023047;
  --color__mid-blue: #219EBC;
  --color__light-blue: #8ECAE6;
  --color__orange: #FB8500;
  --color__yellow: #FFB703;
  --color__glow-dark: rgba(255, 255, 255, 0.3);
  --color__glow-light: #fb860073;

  --color__input-background: #f5f5f5;


  --font-size__12: 12px;
  --font-size__18: 18px;
  --font-size__24: 24px;

  --font-weight__regular: 400;
  --font-weight__bold: 700;

  
  font-family: 'Open Sans', sans-serif;
  background-color: var(--color__Light-gray);
  min-width: 300px;

}

button, a, Link {
  cursor: pointer;
}
p, h1, h2, h3, h4, h5, h6, li { 
  margin: 0;
  color: var(--color__dark-gray);
  font-weight: 300;
}
button:hover { 
  background-color: var(--color__yellow) !important;
}
button:active { 
  background-color: var(--color__orange) !important;
}


.orange {
  color: var(--color__orange) !important;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color__Light-gray);
  color: var(--color__dark-gray);
  transition: background-color 0.3s ease;
  min-width: 380px;
}

.app.dark {
  --color__dark-blue: #f5f5f5;
  --color__Light-gray: #023047;
  --color__dark-gray: #f5f5f5;
  --color__glow-light: rgba(255, 255, 255, 0.3);

}


header {
  width: 100%;
  max-width: 1300px;
  min-width: 380px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  padding: 0 30px;
  box-sizing: border-box;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
  z-index: 50;
}

header h1 {
  color: var(--color__mid-blue);
  align-self: center;
  font-family: 'Marck Script', cursive;
  font-size: 32px;
  transform: rotate(-2deg)
}
.theme-toggle { 
  align-self: center;
  margin-right: 30px;
}

section { 
  justify-content: center;
  align-items: center;
  color: white;
  scroll-snap-align: start;
  width: 100vw;
  max-width: 1300px;
  scroll-snap-align: start;
}
  
.container { 
  width: 100vw;
  max-width: 1300px;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
}

.desktopExtraContent { 
  display: unset;
}

.MainPage { 
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding-top: 100px;
}
header {
  width: 100%;
  max-width: 1300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  padding: 0 30px;
  box-sizing: border-box;
  position: absolute;
  top: 30px;
}

header h1 {
  color: var(--color__mid-blue);
  align-self: center;
  font-family: 'Marck Script', cursive;
  font-size: 32px;
  transform: rotate(-2deg)
}
.theme-toggle { 
  align-self: center;
}

.mainBanner {
  width: 100%;
  max-width: 1300px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px; 
}
.mainBannerContent { 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mainBannerContent div { 
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.mainBannerContent div h1 {
  margin: 24px 0;
  font-size: 32px;
  font-weight: 900;
}
.mainBannerContent div p { 
  margin: 0 20px 0 0;
  line-height: 1.5em;
}
.mainBanner div div button {
  width: 160px;
  height: 40px;
  margin: 5px;
  background-color: var(--color__dark-blue);
  color: var(--color__Light-gray);
  border: none;
  border-radius: 10px;
  font-size: var(--font-size__18);
  font-weight: var(--font-weight__bold)
}
.mainBanner div div button:first-of-type { 
  margin-top: 50px;
}
.mainBannerContent div img { 
  width: 350px;
  height: 350px;
  border-radius: 50%;
  border: 10px solid var(--color__yellow)
}
.mainBannerSocialMedia { 
  margin: 180px 0 50px;
}
.mainBannerSocialMedia ul { 
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  width: 140px;
}
.mainBannerSocialMedia ul li { 
  margin: 5px;
}
.mainBannerSocialMedia ul li img { 
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.mainBannerSocialMedia ul li img:hover { 
  transform: scale(1.02)
}








.content { 
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  
}
.aboutMe { 
  height: fit-content;
  width: 100vw;
  max-width: 1300px;
  margin-bottom: 100px;
}
.aboutMeTop { 
  display: flex;
  flex-direction: column;
  padding: 50px 30px 0; 
  max-width: 100%;
  box-sizing: border-box;
}
.aboutMeTopLeft { 
  width: 70%;
  padding: 100px 0;
  color: var(--color__mid-blue);
  margin: auto;
}
.aboutMeTopLeft p { 
  width: 80%;
  margin: auto;
}
.aboutMeTopRight { 
  margin-top: 50px;
}
.aboutMeTopRight ul { 
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  margin: auto;
  justify-content: center;
}
.aboutMeTopRight ul li { 
  height: 150px;
  width: 150px;
  border: 1px solid Var(--color__mid-blue);
  border-radius: 15px;
  margin: 5px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 5px var(--color__mid-blue), 0 0 2px var(--color__mid-blue),
  0 0 2px #0ba9ca, 0 0 2px #0ba9ca;
  -webkit-animation: blink 0.7s infinite alternate;
  animation: blink 0.7s infinite alternate;
  cursor: pointer;
}
.aboutMeTopRight ul li img { 
  width: 50px;
  margin: auto;
  color: var(--color__dark-gray)
}

.aboutMeTitle { 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--color__dark-blue);
  font-size: var(--font-size__18);
  width: 100%;
  max-width: 100vw;
}
.aboutMeTitle h2 { 
  width: fit-content;
}
.titleLine { 
  height: 3px;
  width: 40%;
  background-color: var(--color__mid-blue);
  align-self: center;
}

.mySkillsTitle, .myWorksTitle { 
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.skillsEducation h3, .skillsExperience h3 { 
  font-weight: 700;
  font-size: var(--font-size__18);
  margin-top: 60px;
}

.skillContent, .workContent { 
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  max-width: 1300;
  padding: 0 30px;
  box-sizing: border-box;
  margin-bottom: 100px;
}
.skillsEducation, .skillsExperience { 
  width: 40%;
  text-align: center;
}
.skillBox { 
  width: 90%;
  margin: 15px auto;
  height: fit-content;
  border: 1px solid var(--color__mid-blue);
  border-radius: 10px;
  padding: 15px 20px;
  box-sizing: border-box;
  text-align: left;
  cursor: pointer;
}
.skillTitle h6, .componentTitle h6 { 
  font-size: var(--font-size__18);
  font-weight: 600;
  margin-bottom: 5px;
}
.skillBox:hover .skillTitle h6{ 
  color: var(--color__yellow)
}
.skillBox:hover .skillDuration p{ 
  color: var(--color__orange)
}
.skillDuration { 
  font-size: var(--font-size__12);
  margin-bottom: 8px;
}

.myComponents { 
  width: 95%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin: auto;
}

.componentBox { 
  width: 330px;;
  margin: 50px auto 25px;
  height: fit-content;
  border: 1px solid var(--color__mid-blue);
  border-radius: 10px;
  padding: 15px;
  box-sizing: border-box;
  text-align: left;
  cursor: pointer;
}
.componentImg { 
  width: 95%;
  height: 200px;
  margin: auto;
  /* border: 1px solid var(--color__mid-blue); */
  border-radius: 10px;
  background-repeat: no-repeat;
  background-position: top;
  background-size:cover;
  z-index: 2;
}
.componentImg:hover {
  background-position: bottom;
  transition: background-position 2s ease-out;
}
.componentImg:not(:hover) { 
  background-position: top;
  transition: background-position 0.5s ease-in-out;
}

.componentTitle { 
  margin: 10px;
  margin-top: 20px;
}
.componentDesc { 
  max-width: 100%;
  word-wrap: break-word;
  margin: 10px;
}



footer {
  padding: 20px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}



.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
  min-width: unset;
  max-width: unset;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 38px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}


.sectionTitle { 
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1300px;
}
.sectionTitle h1 { 
  width: fit-content;
  margin: auto;
}







.spaceForMobile { 
  height: 0;
}

.aboutMeTop { 
  width: 100%;
  max-width: 1300px;
  margin: auto;
  justify-content: center;
  
}

.aboutMeTopLeft { 
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: auto;
  padding: 30px 0;
}
.AboutMeTopLeftDesc, .AboutMeTopLeftProgress { 
  width: 40%;
  justify-content: center;
  margin: 0;
}
.AboutMeTopLeftDesc { 
  text-align: left;
  line-height: 1.5rem;
  display: flex;
  justify-content: center;
}
.AboutMeTopLeftDesc p {
  /* height: 100%; */
  justify-content: center;
  margin: auto auto;
}
.AboutMeTopLeftProgress ul { 
  padding: 10px 0;
  border-radius: 10px;
}
.AboutMeTopLeftProgress ul li { 
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: left;
  width: 80%;
  height: 1.5rem;
  margin: 20px auto;
}
.AboutMeTopLeftProgress ul li p { 
  font-weight: 700;
}
.outerbar { 
  width: 80px;
  height: 10px;
  background-color: var(--color__dark-blue);
  border-radius: 5px;
  padding: 1px;
  box-sizing: border-box;
  margin: auto 0;
}
.innerbar { 
  width: 80px;
  height: 8px;
  background-color: var(--color__orange);
  border-radius: 5px;
}

.mouseEffect { 
  position:fixed;
  width: 800px;
  height: 800px;
  background: hsla(0, 0%, 100%, 0);
  background: radial-gradient(circle, var(--color__glow-light) 0%, rgba(255, 255, 255, 0) 35%);
  background: -moz-radial-gradient(circle, var(--color__glow-light) 0%, rgba(255, 255, 255, 0) 35%);
  background: -webkit-radial-gradient(circle, var(--color__glow-light) 0%, rgba(255, 255, 255, 0) 35%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr=var(--color__glow-light), endColorstr="#FFFFFF00", GradientType=1 );
  border-radius: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
  transition: all 0.1s linear;
}
.mouseEffect2 { 
  position:fixed;
  width: 40px;
  height: 40px;
  background: hsla(0, 0%, 100%, 0);
  background: radial-gradient(circle, var(--color__glow-light) 0%, rgba(255, 255, 255, 0) 35%);
  background: -moz-radial-gradient(circle, var(--color__glow-light) 0%, rgba(255, 255, 255, 0) 35%);
  background: -webkit-radial-gradient(circle, var(--color__glow-light) 0%, rgba(255, 255, 255, 0) 35%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr=var(--color__glow-light), endColorstr="#FFFFFF00", GradientType=1 );
  border-radius: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 3;
  transition: all 0.4s linear;
}

section { 
  z-index: 3;
}



@media screen and (max-width: 1100px) { 
  .mouseEffect, .mouseEffect2 { 
    opacity: 0;
  }
}

@media screen and (max-width: 740px) { 

  .mouseEffect, .mouseEffect2 { 
    opacity: 0;
  }

  .desktopExtraContent { 
    display: none;
  }

  header h1 { 
    text-align: left;
    font-size: var(--font-size__18)
  }
  .switch { 
    height: 28px;
  }
  .slider {
    border-radius: 26px;
  }
  .slider:before { 
    height: 21px;
    width: 21px;
  }
  .MainPage { 
    height: 100vh;
    padding-top: 0;
  }
  .mainBanner { 
    padding-bottom: 0;
    padding-top: 0;
  }
  .mainBannerContent { 
    flex-direction: column-reverse;
    justify-content: center;
    width: 90%;
    margin: auto;
    padding: 0;
    text-align: center;
    margin-top: 15vh;
  }
  .mainBannerContent div img {
    width: 150px;
    height: 150px;
    margin: 0 auto 30px;
    border: 4px solid var(--color__orange)
  }
  p, h1 { 
    margin: 5px 0;
  }
  .mainBannerContent div p {
    margin-right: 0;
    font-weight: 200;
  }
  .mainBannerContent div h1 { 
    margin: 5px;
  }
  .mainBanner div div button { 
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 5px;
  }
  .mainBanner div div button:first-of-type {
    margin-top: 0px;
  }
  .mainBannerSocialMedia{
    margin-top: 10px;
    margin-bottom: 30px;
    font-weight: 200;
    width: 40%;
    margin: 20px auto 0;
  }
  .mainBannerSocialMedia ul {
    width: 120px;
    margin: auto;
  }
  .mainBannerSocialMedia ul li { 
    align-self: center;
    margin: 20px auto 0;
  }

  .aboutMeTop { 
    flex-direction: column;
  }
  .aboutMeTopLeft { 
    padding-top: 30px;
    padding-bottom: 40px;
  }
  .aboutMeTopLeft p {
    width: 100%;
    font-size: 14px;
    line-height: 20px;
  }
  .aboutMeTopRight ul { 
    max-width: 330px;
    margin: auto;
  }
  .aboutMeTopRight ul li {
    margin: 5px auto;
  }
  .aboutMeTitle { 
    padding-right: 0;
    padding-left: 0;
  }
  .titleLine { 
    width: 30%;
    margin: 10px 0;
    padding: 0;
  }

  .spaceForMobile { 
    height: 30px;
  }

  .skillContent { 
    flex-direction: column;
  }
  .skillsEducation, .skillsExperience { 
    width: 100%;
  }

  .myComponents { 
    width: 95%;
  }
  .componentBox { 
    width: 100%;
  }


  .aboutMeTop { 
    box-sizing: border-box;
  }
  .aboutMeTopLeft {
    flex-direction: column;
  }
  .AboutMeTopLeftDesc, .AboutMeTopLeftProgress { 
    width: 90%;
  }
  .AboutMeTopLeftDesc { 
    margin-top: 20px;
  }
  .AboutMeTopLeftProgress ul li { 
    width: 100%;
    height: fit-content;
    margin: 15px auto;
  }
  .AboutMeTopLeftProgress ul li:first-of-type { 
    margin-top: 10px;
  }
  .AboutMeTopLeftProgress ul li p {
    font-size: 14px;
    font-weight: 500;
  }
  .reverseAboutMe { 
    flex-direction: column-reverse;
  }
}