

.viewOptions ul { 
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
}
.viewOptions ul li { 
    list-style: none;
    width: 150px;
    height: auto;
    margin: 10px;
    padding: 10px;
    box-sizing: border-box;
    text-align: center;
    border-radius: 10px;
    border: 1px solid var(--color__mid-blue);
    box-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 5px var(--color__mid-blue), 0 0 2px var(--color__mid-blue),
  0 0 2px #0ba9ca, 0 0 2px #0ba9ca;
  -webkit-animation: blink 0.7s infinite alternate;
  animation: blink 0.7s infinite alternate;
  z-index: 5;
  cursor:pointer;
}
.activeOption { 
    background-color: var(--color__orange);
    font-weight: 700;
    transition: background-color 0.5s ease;
}

.AppIFrame { 
    max-width: 1300px;
    min-height: 400px;
    margin-top: 50px;
    transition: all 0.7s ease;
    border-radius: 10px;
    overflow-x: hidden;
    overflow-Y: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
    box-shadow: 0 0 2px #fff, 0 0 2px #fff, 0 0 5px var(--color__mid-blue), 0 0 2px var(--color__mid-blue),
  0 0 2px #0ba9ca, 0 0 2px #0ba9ca;
  -webkit-animation: blink 0.7s infinite alternate;
  animation: blink 0.7s infinite alternate;
  z-index: 100;
  margin-bottom: 100px;
}
.AppIFrame::-webkit-scrollbar {
    display: none;
}

.componentDesc { 
    width: 80vw;
    max-width: 1200px;
    margin: auto;
    text-align: left;
}
.componentDesc h1 { 
    font-weight: 900;
    margin-bottom: 20px;
}

.closeComponentBtn { 
    width: 190px;
    height: 50px;
    border-radius: 10px;
    background-color: var(--color__orange);
    border: none;
    font-size: 1.3rem;
    font-weight: 600;
}



@media screen and (max-width: 1500px) { 
    .AppIFrame, .componentDesc { 
        max-width: 90vw;
    }
}
@media screen and (max-width: 640px) { 
    .AppIFrame, .componentDesc { 
        max-width: 90vw;
    }
    .viewOptions ul { 
        flex-direction: column;
    }
}