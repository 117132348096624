@import url('https://fonts.googleapis.com/css2?family=Sniglet&display=swap');

.podcastProjectOnePage { 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-width: 380px;
    max-width: 1300px;
    margin: 0 auto;
    background-color: white;
}

.c1001Header { 
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    padding: 1rem;
}

.c1001Logo {
    font-family: 'Sniglet', cursive;
    font-size:2.5rem;
    color: purple;
}
.c1001Navlinks ul, .c1001Login ul { 
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    width: fit-content;
    justify-content: center;
    text-align: center;
}
.c1001Navlinks ul li, .c1001Login ul li { 
    list-style: none;
    width: fit-content;
    margin: 0 10px;
    height: 4rem;
    line-height: 4rem;
}
.c1001Login ul li button { 
    padding: 10px;
    border: none;
    background-color: purple;
    border-radius: 5px;
    color: white;
    font-weight: 600;
}

.c1001Body { 
    width: 100%;
    min-height: 500px;
    
}
.c1001Banner { 
    width: 100%;
    background-image: URL('https://images.unsplash.com/photo-1529362487499-b149087a4f62?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fG1pY3xlbnwwfDB8MHx8fDI%3D&auto=format&fit=crop&w=800&q=60');
    background-size: cover;
    background-repeat: no-repeat;
    background-position:80%;
    display: flex;
    flex-direction: row;
    height: 60vh;
}

@import url('https://fonts.googleapis.com/css2?family=Grape+Nuts&display=swap');

.c1001BannerLeft { 
    padding-top: 10vh;
    color: white;
    padding-left: 10vh;
    display: flex;
    flex-direction: column;
}
.c1001BannerLeft h1 { 
    color: white;
    font-weight: 700;
    font-size: 2.5rem;
}
.c1001BannerLeft p { 
    color: white;
    font-weight: 400;
    width: 50%;
    margin-top: 2rem;
    line-height: 1.5rem;
}
.c1001BannerBtn { 
    margin: 30px 0 50px;;
    padding: 20px;
    max-width: 190px;
    background-color: white;
    color: #800080;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    font-weight: 700;
}
.c1001BannerLeft, .c1001BannerLeft h1, .c1001BannerLeft p, .c1001MusicPlayer {
    text-align: left;
}
.c1001BannerBtn { 
    text-align: center;
}

.c1001MusicPlayer { 
    transform: translateY(-40px)
}

.c1001MusicPlayer p { 
    font-family: 'Grape Nuts', cursive;
    color: rgb(0, 3, 176);
    font-size: 3rem;
    font-weight: 700;
    width: 100%;
    margin-left: 10%;
    line-height: 3.4rem;
    transform: rotate(-5deg) translateY(-10px) 
}

.c1001WeeklyPopular { 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
}
.c1001SectionTitle { 
    width: 90%;
    max-width: 1300px;
    padding: 90px 110px 0 80px;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.c1001SectionTitle h3 { 
    font-size: 2rem;
    font-weight: 800;
    color:rgb(38, 39, 121);
    cursor: pointer;
}
.c1001SectionTitle p { 
    font-weight: 500;
    color:rgb(38, 39, 121);
    cursor: pointer;
    line-height: 2rem;

}

.c1001SelfAd { 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 40px auto;
}
.c1001AdLeft { 
    width: 50%;
    text-align: center;
}
.c1001AdLeft img { 
    width: 60%;
    max-height: 100%;
    max-width: 100%;
    padding-top: 20px;
    margin: auto;
}

.c1001AdRight { 
    box-sizing: border-box;
    padding: 40px 20px;
    width: 40%;
}
.c1001AdRight h1 { 
    font-weight: 700;
    margin: 20px 0;
}
.c1001AdRight p { 
    font-weight: 400;
    margin: 20px 0;
    font-size: 0.9rem;
    line-height: 1.5rem;
}
.c1001AdRight p a { 
    /* text-decoration: none; */
    color: black;
}


.c1001LatestBlackBox { 
    width: 70%;
    max-width: 1200px;
    background-color: rgba(0, 0, 0, 0.866);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    margin: 30px auto;
    border-radius: 40px;
    box-sizing: border-box;
    padding: 30px;
}

.c1001LatestImage div { 
    width: 230px;
    height: 230px;
    border-radius: 15px;
    background-image: URL('https://images.unsplash.com/photo-1684071270645-c7516acf0bf0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHx0b3BpYy1mZWVkfDIxOHx0b3dKWkZza3BHZ3x8ZW58MHx8fHx8&auto=format&fit=crop&w=800&q=60');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.c1001LatestDescription { 
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    width: 60%;
    justify-content: center;
}

.c1001LatestDescription h6, .c1001LatestDescription h1, .c1001LatestDescription p { 
    color: white;
}
.c1001LatestDescription h6 { 
    font-size: 1.5rem;
    margin-bottom: 10px;
}
.c1001LatestDescription p { 
    line-height: 1.4rem
}
.c1001LatestDescription p a { 
    color: white;
    text-decoration: none;
}

.c1001LatestDescription h1 { 
    font-weight: 900;
    margin-bottom: 15px;
    font-size: 2.5rem;
    color: var(--color__yellow)
}

.c1001SearchOptions ul {
     margin: 20px auto 0;
     padding: 0;
     display: flex;
     flex-direction: row;
     width: fit-content;
     max-width: 90vw;
     flex-wrap: wrap;
     text-align: center;
     justify-content: center;
}
.c1001SearchOptions ul li { 
    list-style: none;
    padding: 8px 16px;
    border: 1px solid var(--color__mid-blue);
    border-radius: 30px;
    margin: 5px 8px;
}
.c1001SearchOptions ul li:hover { 
    background-color: var(--color__orange);
    border: 1px solid var(--color__orange);
    color: white;
    font-weight: 600;
}

.c1001SelfAdSecond { 
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    justify-content: center;
}
.c1001ExploreLatest { 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: 40px auto;
    justify-content: center;
    height: fit-content;
}

.c1001SelfAdThird { 
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    margin: auto;
    text-align: center;
}
.c1001SelfAdThird h4 { 
    font-weight: 600;
    font-size: 1.9rem;
}
.c1001SelfAdThird p { 
    color: purple;
    font-weight: 300;
    margin: 10px auto;
    font-size: 1.6rem;
}
.c1001SelfAdThird h1 { 
    font-size: 2.8rem;
    font-weight: 900;
}

.c1001PodcastIcons ul { 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.c1001PodcastIcons ul li { 
    list-style: none;
    margin: 0 5px;
}



.c1001TopPodcasters { 
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 90%;
    margin: 40px auto;
    justify-content: center;
    height: fit-content;
}



.c1001Subscription { 
    display: flex;
    flex-direction: column;
    width: 90%;
    justify-content: center;
    margin: 40px auto 20px;
    text-align: center;
    margin-bottom: 100px;
}

.c1001PlanSelect { 
    display: flex;
    flex-direction: row;
    justify-content: center;
    border: 2px solid black;
    padding: 2px;
    border-radius: 10px;
    width: fit-content;
    margin: 20px auto;
    text-align: center;
}

.c1001PlanSelect p { 
    margin: 5px 5px;
    padding: 5px 10px;;
    border-radius: 5px;
}
.c1001PlanSelect p:last-child{ 
    background-color: rgb(210, 162, 255);
    transition: background-color 0.3s ease;
}


.c1001PlanBox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    width: 90%;
    margin: auto;
}
.c1001Plan { 
    width: 350px;
    max-width: 90vw;
    margin: 10px;
    box-sizing: border-box;
    padding: 10px;
    border-radius: 20px;
    background-color: rgb(227, 227, 227)
}
.c1001Plan h3 { 
    margin: 30px auto 20px;
    padding: 10px 20px;
    background-color: rgb(255, 206, 115);
    width: 120px;
    border-radius: 15px;
}
.c1001Plan ul { 
    margin: 30px 0;
    padding: 0;
}
.c1001Plan ul li { 
    /* text-align: left; */
    list-style:none;
    line-height: 2rem;
}

.c1001PlanSubscrption { 
    width: 130px;
    height: 40px;
    border: none;
    border-radius: 15px;
    background-color: rgb(194, 131, 253);
    color: white;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 1.05rem;
}
.c1001Yearly { 
    background-color: rgb(194, 131, 253);
}
.c1001Yearly button { 
    background-color: rgb(227, 227, 227);
    color: black;
}


.c1001Footer { 
    background-color: rgb(50, 50, 50);
    height: 100px;
    width: 100%;
    text-align: center;
    padding-top: 30px;
}
.c1001Footer p { 
    color: white;
}





@media screen and (max-width: 1500px) {
    .podcastProjectOnePage, .c1001SectionTitle { 
        max-width: 95vw;
    }
    .c1001LatestDescription { 
        width: 100%;
    }
    .c1001LatestBlackBox { 
        max-width: 92vw;
        width: 100%;
        margin: 10px auto;
    }
}

@media screen and (max-width: 1200px) { 
    .c1001LatestBlackBox { 
        justify-content: center;
    }
}

@media screen and (max-width: 1000px) {
    .c1001Banner { 
        height: fit-content !important;
        padding: 30px 20px 0;
        box-sizing: border-box;
    }
    .c1001BannerLeft { 
        padding-left: 0;
        margin: auto;
    }
    .c1001BannerLeft h1 {
        text-align: center;
    }
    .c1001BannerLeft p { 
        text-align: center;
        margin: 20px auto;
    }
    .c1001BannerBtn, .c1001MusicPlayer { 
        margin: 0 auto 30px;
    }
    .c1001SectionTitle { 
        width: 95vw;
        padding-right: 0;
        padding-left: 0;
    }
    .c1001SectionTitle p { 
        display: none;
    }

}

@media screen and (max-width: 640px) {
    .c1001Header { 
        justify-content: center;;
    }
    .c1001Navlinks, .c1001Login { 
        display: none;
    }
    .c1001BannerLeft { 
        padding-left: 0;
    }
    .c1001BannerLeft h1 {
        text-align: center;
    }
    .c1001BannerLeft p { 
        text-align: center;
        margin: 20px auto;
    }
    .c1001BannerBtn, .c1001MusicPlayer { 
        margin: 0 auto 30px;
    }
    .c1001Banner { 
        height: calc(99vh - 8rem);
    }
    .c1001MusicPlayer p { 
        font-size: 1.6rem;
        line-height: 1.7rem;
    }

    .c1001SectionTitle  { 
        text-align: center;
        justify-content: center;
    }
    .c1001SelfAd { 
        flex-direction: column;
        margin: auto;
    }
    .c1001AdLeft, .c1001AdRight { 
        width: 100%;
    }
}
